<template>
    <b-card no-body>
        <template v-if="!readOnly">
            <Header />
            <hr class="m-0" />
        </template>
        <b-form v-if="data" @submit.prevent="saveData">
            <b-card-body class="p-0 pt-4 d-flex flex-column" style="row-gap: 12px">
                <div class="p-2">
                    <b-row>
                        <b-col sm="3" class="">
                            <label for="cus4">Reason Type</label>
                            <b-form-radio-group
                                v-model="data.reasonTypeId"
                                :options="customersTypes"
                                class="d-flex"
                                value-field="id"
                                :disabled="readOnly"
                                text-field="name"
                                required
                            ></b-form-radio-group>
                        </b-col>
                    </b-row>
                </div>

                <b-row v-if="data.reasonTypeId == 2">
                    <b-col sm="3">
                        <label for="comp4">Business Name* </label>
                        <b-form-input
                            size="sm"
                            type="text"
                            :readOnly="readOnly"
                            placeholder="Business name here"
                            v-model="data.businessName"
                            required
                        ></b-form-input>
                    </b-col>
                    <b-col sm="3">
                        <label for="comp4">KVK - nummer/Chamber of Commerce</label>
                        <b-form-input
                            size="sm"
                            type="text"
                            :readOnly="readOnly"
                            placeholder="KVK - nummer here"
                            v-model="data.businessId"
                        ></b-form-input>
                    </b-col>
                    <b-col sm="3">
                        <label for="comp4">Doing Business Name* </label>
                        <b-form-input
                            size="sm"
                            type="text"
                            :readOnly="readOnly"
                            placeholder="Business legal name here"
                            v-model="data.doingBusinessName"
                            required
                        ></b-form-input>
                    </b-col>
                    <b-col sm="3">
                        <label for="comp4">Business Address* </label>
                        <b-form-input
                            size="sm"
                            type="text"
                            :readOnly="readOnly"
                            placeholder="Address here"
                            v-model="data.address2"
                            required
                        ></b-form-input>
                    </b-col>

                    <b-col sm="3">
                        <label for="fname4">Business Phone</label>
                        <b-form-input
                            size="sm"
                            type="tel"
                            :readOnly="readOnly"
                            placeholder="Phone number here"
                            v-model="data.phone2"
                        ></b-form-input>
                    </b-col>
                    <b-col sm="3">
                        <label for="fname4">Business Email</label>
                        <b-form-input
                            size="sm"
                            type="email"
                            :readOnly="readOnly"
                            placeholder="Email here"
                            v-model="data.email2"
                        ></b-form-input>
                    </b-col>
                </b-row>

                <h4 v-if="data.reasonTypeId == 2" class="mt-4">Contact Person Information</h4>

                <b-row style="row-gap: 12px">
                    <b-col sm="3">
                        <label>Last Name<span v-if="data.reasonTypeId == 1">*</span> </label>
                        <b-form-input
                            size="sm"
                            id="title4"
                            type="text"
                            :readOnly="readOnly"
                            placeholder="Last name here"
                            v-model="data.lastName"
                            :required="data.reasonTypeId == 1"
                        ></b-form-input>
                    </b-col>
                    <b-col sm="3">
                        <label for="comp4">First Name<span v-if="data.reasonTypeId == 1">*</span> </label>
                        <b-form-input
                            size="sm"
                            type="text"
                            :readOnly="readOnly"
                            placeholder="First name here"
                            v-model="data.firstName"
                            :required="data.reasonTypeId == 1"
                        ></b-form-input>
                    </b-col>
                    <b-col sm="3">
                        <label for="comp4">Middle Name </label>
                        <b-form-input
                            size="sm"
                            type="text"
                            :readOnly="readOnly"
                            placeholder="Name here"
                            v-model="data.middleName"
                        ></b-form-input>
                    </b-col>
                </b-row>
                <b-row style="row-gap: 12px">
                    <b-col sm="3">
                        <label for="comp4">Address </label>
                        <b-form-input
                            size="sm"
                            type="text"
                            :readOnly="readOnly"
                            placeholder="Address here"
                            v-model="data.address1"
                        ></b-form-input>
                    </b-col>
                    <!-- <b-col sm="3" v-if="data.reasonTypeId == 1">
                        <label for="comp4">Address 2* </label>
                        <b-form-input
                            size="sm"
                            type="text"
                            :readOnly="readOnly"
                            placeholder="Address here"
                            v-model="data.address2"
                            required
                        ></b-form-input>
                    </b-col> -->
                    <b-col sm="3">
                        <label for="fname4">District<span v-if="data.reasonTypeId == 1">*</span></label>
                        <b-form-select
                            size="sm"
                            v-model="data.cityId"
                            :disabled="readOnly"
                            :options="[{ id: null, name: 'Please select an option', disabled: true }, ...cities]"
                            text-field="name"
                            value-field="id"
                            :required="data.reasonTypeId == 1"
                        ></b-form-select>
                    </b-col>
                </b-row>
                <b-row style="row-gap: 12px">
                    <b-col sm="3">
                        <label for="fname4">Phone 1<span v-if="data.reasonTypeId == 1">*</span></label>
                        <b-form-input
                            size="sm"
                            type="tel"
                            :readOnly="readOnly"
                            placeholder="Phone number here"
                            v-model="data.phone1"
                            :required="data.reasonTypeId == 1"
                        ></b-form-input>
                    </b-col>
                    <b-col sm="3" v-if="data.reasonTypeId == 1">
                        <label for="fname4">Phone 2</label>
                        <b-form-input
                            size="sm"
                            type="tel"
                            :readOnly="readOnly"
                            placeholder="Phone number here"
                            v-model="data.phone2"
                        ></b-form-input>
                    </b-col>
                </b-row>
                <b-row style="row-gap: 12px">
                    <b-col sm="3">
                        <div class="d-flex align-items-start mb-1">
                            <label for="fname4" class="mr-2 mb-0"
                                >Email 1<span v-if="data.reasonTypeId == 1">*</span>
                            </label>
                            <b-badge :variant="'secondary'" v-if="data.reasonTypeId == 1">
                                <i class="mdi mdi-check" v-if="data.email1VerificationEmailSent"></i>
                                <i class="mdi mdi-alert-circle-outline" v-else></i>
                                {{ data.email1VerificationEmailSent ? 'Verification Sent' : 'Verification Pending' }}
                            </b-badge>
                        </div>
                        <b-form-input
                            type="email"
                            size="sm"
                            :readOnly="readOnly"
                            placeholder="Email here"
                            v-model="data.email1"
                            :required="data.reasonTypeId == 1"
                        />
                    </b-col>

                    <b-col sm="3" class="d-flex align-items-end" v-if="data.reasonTypeId == 1">
                        <div class="d-flex align-items-center" v-if="!data.email1IsVerified">
                            <b-button
                                size="sm"
                                variant="primary"
                                v-if="!readOnly"
                                @click="sendVerification(data.email1, 1)"
                            >
                                Send Verification Email
                            </b-button>
                        </div>
                    </b-col>
                    <b-col sm="3" v-if="data.reasonTypeId == 1">
                        <div class="d-flex align-items-start mb-1">
                            <label for="fname4" class="mr-2 mb-0">Email 2 </label>
                            <b-badge :variant="'secondary'">
                                <i class="mdi mdi-check" v-if="data.email2VerificationEmailSent"></i>
                                <i class="mdi mdi-alert-circle-outline" v-else></i>
                                {{ data.email2VerificationEmailSent ? 'Verification Sent' : 'Verification Pending' }}
                            </b-badge>
                        </div>
                        <b-form-input
                            size="sm"
                            type="email"
                            placeholder="Email here"
                            :readOnly="readOnly"
                            v-model="data.email2"
                        ></b-form-input>
                    </b-col>
                    <b-col sm="3" class="d-flex align-items-end" v-if="data.reasonTypeId == 1">
                        <div class="d-flex align-items-center" v-if="!data.email2IsVerified">
                            <b-button
                                size="sm"
                                variant="primary"
                                v-if="!readOnly"
                                @click="sendVerification(data.email2, 2)"
                            >
                                Send Verification Email
                            </b-button>
                        </div>
                    </b-col>
                </b-row>

                <b-row v-if="data.reasonTypeId == 2">
                    <b-col sm="3">
                        <label for="comp4">Business Comment</label>
                        <b-form-textarea
                            size="sm"
                            type="text"
                            :readOnly="readOnly"
                            placeholder="Comment here"
                            v-model="data.businessComments"
                        ></b-form-textarea>
                    </b-col>
                </b-row>
            </b-card-body>
            <hr class="my-4" />
            <div v-if="!readOnly && hasPermission">
                <div class="btn-grp text-right">
                    <b-button :disabled="loadingSave" variant="primary" class="px-4" type="submit">
                        <b-spinner small v-if="loadingSave"></b-spinner>
                        <span v-else>Save</span>
                    </b-button>
                </div>
            </div>
        </b-form>
    </b-card>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '@/components/Header.vue';
export default {
    name: 'CustomerMain',
    components: {
        Header,
    },
    props: {
        readOnly: Boolean,
    },
    data: () => ({
        optionsCountry: [
            { value: null, text: 'Please select an option' },
            { value: 'Aruba', text: 'Aruba' },
            { value: 'US', text: 'Estados Unidos' },
        ],
        loadingCustomerTypes: true,
        loadingVerification: true,
        data: {
            id: 0,
            reasonTypeId: 0,
            firstName: '',
            middleName: '',
            lastName: '',
            businessId: '',
            businessName: '',
            businessLegalName: '',
            titleId: null,
            cityId: null,
            address1: '',
            address2: '',
            address3: '',
            phone1: '',
            phone2: '',
            email1: '',
            email1IsVerified: true,
            email2: '',
            email2IsVerified: true,
        },
        loan: null,
        loadingSave: false,
    }),
    computed: {
        ...mapGetters({
            customer: 'customer/getCustomer',
            customersTypes: 'general/customersTypes/getcustomersTypes',
            countries: 'general/countries/getCountries',
            cities: 'general/cities/getCities',
            authorizations: 'auth/getAuthorizations',
        }),
        expectedFee() {
            if (!this.loan) return 0;
            return (2.5 * this.loan.loanAmount) / 100;
        },
        hasPermission() {
            return this.authorizations.includes('/LoanApplications-Add');
        },
    },
    methods: {
        setData() {
            this.data = this.customer;
        },
        async getCustomerTypes() {
            this.loadingCustomerTypes = true;
            await this.$store.dispatch('general/customersTypes/get_allCustomersTypes', { skip: 1, take: 9999 });
            this.loadingCustomerTypes = false;
        },
        async getData() {
            await this.$store.dispatch('general/countries/get_allCountries', { skip: 1, take: 9999 });
            await this.$store.dispatch('general/cities/get_allCities', { skip: 1, take: 9999 });
            if (this.$route.path.includes('/applications')) {
                const response = await this.$store.dispatch(
                    'loan/applications/get_loanById',
                    this.$route.query.loanApplicationId,
                );
                this.loan = response?.dataResult;
            }
        },
        async saveData() {
            this.loadingSave = true;
            await this.$store.dispatch('customer/update_customer', this.data);
            this.loadingSave = false;
        },
        async sendVerification(email, number) {
            if (!email) return;
            this.loadingVerification = true;
            let payload = {
                customerId: this.$route.params.customer,
                emailNumber: number,
                email,
            };
            await this.$store.dispatch('customer/send_emailVerification', payload);
            this.loadingVerification = false;
        },
    },
    mounted() {
        this.setData();
        this.getCustomerTypes();
        this.getData();
    },
    watch: {
        customer: function () {
            this.setData();
        },
    },
};
</script>
