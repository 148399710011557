<template>
    <b-overlay :show="loading" rounded="sm">
        <div>
            <b-card no-body class="mb-4">
                <template v-if="!readOnly">
                    <Header />
                    <hr class="mb-4" />
                </template>

                <!-- Search Control -->
                <div class="d-flex justify-content-between">
                    <b-form-group class="w-25">
                        <b-input-group>
                            <b-form-input
                                size="sm"
                                id="filter-input"
                                v-model="filter"
                                type="search"
                                placeholder="Type to Search"
                            ></b-form-input>

                            <b-input-group-append>
                                <b-button size="sm" variant="danger" :disabled="!filter" @click="filter = ''">
                                    Clear
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                    <div class="d-flex align-items-center btn-grp" v-if="!readOnly">
                        <b-button variant="primary" v-b-modal.modal-upload-files class="d-flex align-items-center mr-0">
                            <feather type="upload" class="feather-sm"></feather>
                            <span class="ml-1">Upload File</span>
                        </b-button>
                    </div>
                </div>
                <!-- End Section -->

                <div>
                    <h4>Customer Documents</h4>
                    <b-table
                        style="min-height: 250px"
                        show-empty
                        responsive
                        small
                        class="mb-0"
                        head-variant="light"
                        :items="documents"
                        :filter="filter"
                        :fields="fields"
                        hover
                    >
                        <template #cell(id)="data"> #{{ data.item.id }} </template>
                        <template #cell(createDate)="data"> {{ $formatDate(data.item.createDate) }} </template>
                        <template #cell(documentDate)="data"> {{ $formatDate(data.item.documentDate) }} </template>
                        <template #cell(documentExpirationDate)="data">
                            {{ $formatDate(data.item.documentExpirationDate) }}
                        </template>
                        <template #cell(isActive)="data">
                            <b-badge variant="success" v-if="data.item.isActive">Active</b-badge>
                            <b-badge variant="danger" v-else>Archived</b-badge>
                        </template>

                        <template #cell(view)="data">
                            <div class="d-flex">
                                <b-button
                                    variant="outline-secondary"
                                    size="sm"
                                    class="d-flex align-items-center mr-2"
                                    @click="goToView(data.item.sourcePath)"
                                >
                                    <feather type="search" class="feather-sm"></feather>
                                    <span class="ml-1">View</span>
                                </b-button>
                                <b-button
                                    variant="outline-danger"
                                    size="sm"
                                    class="d-flex align-items-center"
                                    @click="removeDocument(data.item)"
                                >
                                    <feather type="trash" class="feather-sm"></feather>
                                    <span class="ml-1">Delete</span>
                                </b-button>
                            </div>
                        </template>
                    </b-table>
                    <!-- End Section -->
                    <!-- Pagination -->
                    <div class="p-3 d-flex align-items-center">
                        <b-form-group
                            label="Per page"
                            label-for="per-page-select"
                            label-cols-sm="6"
                            label-cols-md="4"
                            label-cols-lg="9"
                            label-size="sm"
                            label-class="fw-medium"
                            class="mb-0"
                        >
                            <b-form-select
                                @input="getData"
                                id="per-page-select"
                                v-model="perPage"
                                :options="pageOptions"
                                size="sm"
                            ></b-form-select>
                        </b-form-group>
                        <div class="ml-auto">
                            <b-pagination
                                @input="getData"
                                v-model="currentPage"
                                :total-rows="totalDcuments"
                                :per-page="perPage"
                                align="fill"
                                class="my-0"
                            ></b-pagination>
                        </div>
                    </div>
                    <!-- End Section -->
                </div>
            </b-card>

            <b-modal id="modal-upload-files" title="Upload Files" hide-footer centered @close="clearData">
                <label class="fw-medium mb-1" for="document-types">Document Type</label>
                <b-form-select
                    id="document-types"
                    :options="[{ id: null, description: 'Please select an option' }, ...documentTypes]"
                    text-field="description"
                    value-field="id"
                    size="sm"
                    class="mb-3"
                    v-model="documentType"
                    required
                ></b-form-select>

                <label class="fw-medium mb-1" for="document-date">Document Date</label>
                <b-form-input
                    id="document-date"
                    type="date"
                    size="sm"
                    class="mb-3"
                    v-model="documentDate"
                    required
                ></b-form-input>

                <label class="fw-medium mb-1" for="document-expiration-date">Document Expiration Date</label>
                <b-form-input
                    id="document-expiration-date"
                    type="date"
                    size="sm"
                    class="mb-3"
                    v-model="documentExpirationDate"
                ></b-form-input>

                <div class="d-flex">
                    <b-form-file
                        id="file"
                        v-model="file"
                        placeholder="Choose a file..."
                        drop-placeholder="Drop file here..."
                        accept=".docx,.png,.jpg,.pdf"
                        @input="addFile"
                    ></b-form-file>
                </div>
                <h5 class="mb-2 mt-4" v-if="files.length > 0">Files</h5>
                <ul class="my-2 p-0">
                    <li
                        v-for="(item, idx) in files"
                        :key="idx"
                        class="d-flex justify-content-between align-items-center p-2 border-bottom"
                    >
                        <span>{{ item.name }}</span>
                        <b-button
                            variant="outline-danger"
                            size="sm"
                            class="d-flex align-items-center ml-2"
                            @click="files.splice(files.indexOf(item), 1)"
                        >
                            <feather type="trash" class="feather-sm"></feather>
                        </b-button>
                    </li>
                </ul>

                <div class="d-flex justify-content-end mt-4 btn-grp">
                    <b-button variant="secondary" @click="$bvModal.hide('modal-upload-files')" class="mr-2"
                        >Cancel</b-button
                    >
                    <b-button
                        variant="primary"
                        class="d-flex align-items-center mr-0"
                        @click="uploadFiles"
                        :disabled="files.length == 0 || documentType == null || loadingSave"
                    >
                        <b-spinner small v-if="loadingSave"></b-spinner>
                        <template v-else>
                            <feather type="upload" class="feather-sm"></feather>
                            <span class="ml-1">Upload</span>
                        </template>
                    </b-button>
                </div>
            </b-modal>
        </div>
    </b-overlay>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '@/components/Header.vue';
export default {
    name: 'CustomerDocuments',
    components: {
        Header,
    },
    props: {
        new: Boolean,
        readOnly: Boolean,
    },
    data: () => ({
        page: {
            title: 'SeachTable',
        },
        filter: null,
        fields: [
            {
                key: 'id',
                sortable: true,
            },
            {
                key: 'documentTypeName',
                label: 'Document Type',
                sortable: true,
            },
            {
                key: 'createDate',
                label: 'Upload Date',
                sortable: true,
            },
            {
                key: 'documentDate',
                label: 'Document Date',
            },
            {
                key: 'documentExpirationDate',
                label: 'Document Expiration Date',
            },
            {
                key: 'name',
                label: 'File Name',
                sortable: true,
            },
            {
                key: 'isActive',
                label: 'Status',
                sortable: true,
            },
            {
                key: 'view',
                label: 'View',
                sortable: true,
            },
        ],

        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 20, 40, { value: 100, text: 'Show a lot' }],
        file: null,
        documentType: null,
        loading: false,
        files: [],
        currentRoute: window.location.pathname,
        loadingSave: false,
        documentDate: null,
        documentExpirationDate: null,
    }),
    computed: {
        ...mapGetters({
            customer: 'customer/getCustomer',
            documents: 'customer/documents/getDocuments',
            totalDcuments: 'customer/documents/getTotalDocuments',
            documentTypes: 'customer/documents/getDocumentTypes',
        }),
    },
    methods: {
        clearData() {
            this.file = null;
            this.documentType = null;
            this.files = [];
            this.documentDate = null;
            this.documentExpirationDate = null;
        },
        async getData() {
            this.loading = true;
            await this.$store.dispatch('customer/documents/get_documents', {
                skip: this.currentPage,
                take: this.perPage,
                customerId: this.$route.params.customer,
            });
            this.loading = false;
        },
        async getDocumentTypes() {
            await this.$store.dispatch('customer/documents/get_documentTypes');
        },
        addFile() {
            if (!this.file) return;
            this.files.push(this.file);
            this.file = null;
        },
        async removeDocument(document) {
            await this.$store.dispatch('customer/documents/remove_document', document.id);
            await this.getData();
        },
        fileToBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result.split(',')[1]); // Strip metadata if needed
                reader.onerror = (error) => reject(error);
                reader.readAsDataURL(file);
            });
        },
        async uploadFiles() {
            this.loadingSave = true;

            const blob = await this.fileToBase64(this.files[0]);

            let data = {
                fileName: this.files[0].name,
                customerId: this.$route.params.customer,
                documentTypeId: this.documentType,
                documentDate: this.documentDate,
                documentExpirationDate: this.documentExpirationDate,
                file: blob,
            };

            await this.$store.dispatch('customer/documents/add_documentsInCDN', data);
            await this.getData();
            this.clearData();
            this.$bvModal.hide('modal-upload-files');
            this.loadingSave = false;
        },
        goToView(path) {
            window.open(`${path}`, '_blank');
        },
        async disableDocument(document, status) {
            let data = {
                id: document.id,
                name: document.name,
                sourcePath: document.sourcePath,
                isActive: status,
            };
            await this.$store.dispatch('customer/documents/update_documentStatus', data);
            await this.getData();
        },
    },
    mounted() {
        if (!this.new) {
            this.getData();
        }
    },
    created() {
        if (!this.new) {
            this.getData();
        }
        this.getDocumentTypes();
    },
};
</script>
